import React, { useContext, useEffect, useLayoutEffect } from "react";

import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

// FontAwesome
import { faIgloo, faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Context
import PanelContext from "../../contexts/PanelContext";
import MediaQueryContext from "../../contexts/MediaQueryContext";

// Utils
import { navSelectAni, navClickScroll } from "./navUtils";

// CSS
import "../../App.css";
import CSS from "./nav.module.css";

gsap.registerPlugin(ScrollToPlugin);

export default function Nav({ iden, setScrollPosition, ...props }) {
  const { page, setPage } = useContext(PanelContext);
  const { mediaQuery } = useContext(MediaQueryContext);

  useEffect(() => {
    if (
      page.current === null ||
      page.introRef === null ||
      page.aboutRef === null ||
      page.overlayRef === null ||
      page.creativeRef === null ||
      page.projectsRef === null
    ) {
      return;
    } else if (page.current === undefined) {
      return;
    } else if (page.current.current !== null) {
      if (page.current === page.aboutRef) {
        gsap.to(`.${CSS.overlay_navSq_text}`, { duration: 0.5, color: "rgba(114, 85, 0, 1)" });
      } else {
        gsap.to(`.${CSS.overlay_navSq_text}`, { duration: 0.5, color: "rgba(230, 208, 139, 1)" });
      }

      switch (page.current.current.id) {
        case "Intro":
          return navSelectAni("#nav_intro_sq", `.${CSS.overlay_navSquare}`);
        case "About":
          return navSelectAni("#nav_about_sq", `.${CSS.overlay_navSquare}`);
        case "Projects":
          return navSelectAni("#nav_projects_sq", `.${CSS.overlay_navSquare}`);
        case "Creative":
          return navSelectAni("#nav_creative_sq", `.${CSS.overlay_navSquare}`);
        default:
          return;
      }
    }
  }, [page.current]);

  return (
    <>
      {!iden || iden === undefined ? (
        ""
      ) : iden === "overlaySideNav" ? (
        <div>
          {mediaQuery > 1279 ? (
            <div className={`flexColumn maxHeight justifyCenter ${CSS.overlay_whichPageUI_text}`}>
              <div className={`${CSS.overlay_navSq_box}`}>
                <p id={"navSqIntro"} className={CSS.overlay_navSq_text}>
                  INTRO
                </p>
              </div>

              <div className={`${CSS.overlay_navSq_box}`}>
                <p id={"navSqAbout"} className={CSS.overlay_navSq_text}>
                  ABOUT
                </p>
              </div>

              <div className={`${CSS.overlay_navSq_box}`}>
                <p id={"navSqProjects"} className={CSS.overlay_navSq_text}>
                  PROJECTS
                </p>
              </div>

              <div className={`${CSS.overlay_navSq_box}`}>
                <p id={"navSqCreative"} className={CSS.overlay_navSq_text}>
                  CREATIVE
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className={`flexColumn maxHeight justifyCenter ${CSS.overlay_whichPageUI}`}>
            <div
              className={`${CSS.overlay_navSq_padding}`}
              onClick={() => {
                gsap.delayedCall(0.1, navClickScroll, [
                  { page, setPage, whereTo: page.introRef, setScrollPosition, mediaQuery },
                ]);
              }}
              onMouseEnter={() => {
                gsap.to(`#navSqIntro`, {
                  duration: 0.5,
                  ease: "none",
                  display: "flex",
                  opacity: 1,
                });
              }}
              onMouseLeave={() => {
                gsap.to(`#navSqIntro`, {
                  duration: 0.5,
                  ease: "none",
                  display: "none",
                  opacity: 0,
                });
              }}
            >
              <FontAwesomeIcon
                id={`nav_intro_sq`}
                className={`${CSS.overlay_navSquare}`}
                icon={faSquare}
              ></FontAwesomeIcon>
            </div>

            <div
              className={`${CSS.overlay_navSq_padding}`}
              onClick={() => {
                gsap.delayedCall(0.1, navClickScroll, [
                  { page, setPage, whereTo: page.aboutRef, setScrollPosition, mediaQuery },
                ]);
              }}
              onMouseEnter={() => {
                gsap.to(`#navSqAbout`, {
                  duration: 0.5,
                  ease: "none",
                  display: "flex",
                  opacity: 1,
                });
              }}
              onMouseLeave={() => {
                gsap.to(`#navSqAbout`, {
                  duration: 0.5,
                  ease: "none",
                  display: "none",
                  opacity: 0,
                });
              }}
            >
              <FontAwesomeIcon
                id={`nav_about_sq`}
                className={`${CSS.overlay_navSquare}`}
                icon={faSquare}
              ></FontAwesomeIcon>
            </div>

            <div
              className={`${CSS.overlay_navSq_padding}`}
              onClick={() => {
                gsap.delayedCall(0.1, navClickScroll, [
                  { page, setPage, whereTo: page.projectsRef, setScrollPosition, mediaQuery },
                ]);
              }}
              onMouseEnter={() => {
                gsap.to(`#navSqProjects`, {
                  duration: 0.5,
                  ease: "none",
                  display: "flex",
                  opacity: 1,
                });
              }}
              onMouseLeave={() => {
                gsap.to(`#navSqProjects`, {
                  duration: 0.5,
                  ease: "none",
                  display: "none",
                  opacity: 0,
                });
              }}
            >
              <FontAwesomeIcon
                id={`nav_projects_sq`}
                className={`${CSS.overlay_navSquare}`}
                icon={faSquare}
              ></FontAwesomeIcon>
            </div>

            <div
              className={`${CSS.overlay_navSq_padding}`}
              onClick={() => {
                gsap.delayedCall(0.1, navClickScroll, [
                  { page, setPage, whereTo: page.creativeRef, setScrollPosition, mediaQuery },
                ]);
              }}
              onMouseEnter={() => {
                gsap.to(`#navSqCreative`, {
                  duration: 0.5,
                  ease: "none",
                  display: "flex",
                  opacity: 1,
                });
              }}
              onMouseLeave={() => {
                gsap.to(`#navSqCreative`, {
                  duration: 0.5,
                  ease: "none",
                  display: "none",
                  opacity: 0,
                });
              }}
            >
              <FontAwesomeIcon
                id={`nav_creative_sq`}
                className={`${CSS.overlay_navSquare}`}
                icon={faSquare}
              ></FontAwesomeIcon>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
