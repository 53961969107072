// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* \n======================================================================\nContact Page (toaken out in Mobile)\n======================================================================\n*/\n.container_contact_bg__1spg5 {\n  background-color: lightyellow;\n  overflow: hidden;\n  height: calc((var(--vhMobile, 1vh) * 100) - 4em);\n}", "",{"version":3,"sources":["webpack://src/components/Container/container.module.css"],"names":[],"mappings":"AAAA;;;;CAIC;AACD;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,gDAAgD;AAClD","sourcesContent":["/* \n======================================================================\nContact Page (toaken out in Mobile)\n======================================================================\n*/\n.contact_bg {\n  background-color: lightyellow;\n  overflow: hidden;\n  height: calc((var(--vhMobile, 1vh) * 100) - 4em);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contact_bg": "container_contact_bg__1spg5"
};
export default ___CSS_LOADER_EXPORT___;
