import React, { forwardRef } from "react";

// This is the About Page

// Component
import Container from "../../components/Container/Container";

function Projects({ ...props }, ref) {
  return (
    <>
      <Container ref={ref} id={"Projects"} iden={"projects-max-mobile"}></Container>{" "}
    </>
  );
}

const forwardProjects = forwardRef(Projects);

export default forwardProjects;
