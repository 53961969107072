import React, {forwardRef } from "react";

// This is the Contact Page

// Components
// import Container from "../../components/Container/Container";

import CSS from "./footer.module.css";

function Contact({...props}, ref) {
  return (
    <>
      <div className={`${CSS.footer_mobile}`}>
      © Daniel Thao
      </div>
    </>
  );
}

const forwardContact = forwardRef(Contact)

export default forwardContact