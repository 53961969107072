import gsap from "gsap";

export const navSelectAni = function (navSqId, navSqClasses) {
  gsap.to(navSqClasses, {
    autoAlpha: 1,
    color: "rgba(114, 85, 0, 1)",
    overwrite: true,
    duration: 1,
  });
  gsap.to(navSqId, {
    autoAlpha: 1,
    color: "rgba(230, 208, 139, 1)",
    overwrite: true,
    duration: 1,
  });
};

export const navClickScroll = function ({ page, setPage, whereTo, setScrollPosition, mediaQuery }) {
  setPage({ ...page, current: whereTo, navClick: true });
  gsap.to(`#${page.overlayRef.current.id}`, { duration: 1, scrollTo: `#${whereTo.current.id}`, ease: "power3" });
  setScrollPosition(whereTo.current.offsetTop);

  if(mediaQuery < 1279) {
    gsap.delayedCall(1.5, setPage, [{ ...page, current: whereTo, navClick: false }]);
  } else if(mediaQuery > 1280) {
    gsap.delayedCall(1.1, setPage, [{ ...page, current: whereTo, navClick: false }]);
  }
};
