import React, { forwardRef } from "react";

// This is the About Page

// Component
import Container from "../../components/Container/Container";

function About({ ...props }, ref) {
  return (
    <>
      <Container ref={ref} id={"About"} iden={"about-max-mobile"}></Container>{" "}
    </>
  );
}

const forwardAbout = forwardRef(About);

export default forwardAbout;
