// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer_footer_mobile__1OZJ3 {\n  /* height: 4em; */\n  background-color: rgba(227, 228, 216, 0.3);\n  text-align: center;\n  font-size: 1.5em;\n  padding: 1em;\n  font-family: \"Pontano Sans\", sans-serif;\n  color: rgba(20, 20, 20, 1);\n}\n", "",{"version":3,"sources":["webpack://src/components/Footer/footer.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,0CAA0C;EAC1C,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,uCAAuC;EACvC,0BAA0B;AAC5B","sourcesContent":[".footer_mobile {\n  /* height: 4em; */\n  background-color: rgba(227, 228, 216, 0.3);\n  text-align: center;\n  font-size: 1.5em;\n  padding: 1em;\n  font-family: \"Pontano Sans\", sans-serif;\n  color: rgba(20, 20, 20, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer_mobile": "footer_footer_mobile__1OZJ3"
};
export default ___CSS_LOADER_EXPORT___;
