// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".overlay_scroll__9TJG_ {\n  overflow-y: scroll;\n  background-color: white;\n  height: calc(var(--vhMobile, 1vh) * 100);\n  overflow-x: hidden;\n  /* scroll-behavior: smooth; */\n\n}\n\n.overlay_scroll__9TJG_::-webkit-scrollbar {\n  display: none;\n}\n", "",{"version":3,"sources":["webpack://src/pages/Overlay/overlay.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,wCAAwC;EACxC,kBAAkB;EAClB,6BAA6B;;AAE/B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".scroll {\n  overflow-y: scroll;\n  background-color: white;\n  height: calc(var(--vhMobile, 1vh) * 100);\n  overflow-x: hidden;\n  /* scroll-behavior: smooth; */\n\n}\n\n.scroll::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scroll": "overlay_scroll__9TJG_"
};
export default ___CSS_LOADER_EXPORT___;
