// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Other Audio Option stuff */\n#audioWaves_End1__19Uaq {\n  visibility: hidden;\n}\n#audioWaves_End2__o-Gpk {\n  visibility: hidden;\n}\n#audioWaves_End3__3fIWO {\n  visibility: hidden;\n}\n#audioWaves_End4__3mYWF {\n  visibility: hidden;\n}\n\n#audioWaves_bars__3xVJh {\n  box-shadow: 0em 0em 1em black;\n}\n\n#audioWaves_Start1__2xJwm {\n  visibility: visible;\n  fill: rgba(114, 85, 0, 1);\n}\n#audioWaves_Start2__2jEMK {\n  visibility: visible;\n  fill: rgba(114, 85, 0, 1);\n}\n#audioWaves_Start3__2kxro {\n  visibility: visible;\n  fill: rgba(114, 85, 0, 1);\n}\n#audioWaves_Start4__3jwXi {\n  visibility: visible;\n  fill: rgba(114, 85, 0, 1);\n}\n", "",{"version":3,"sources":["webpack://src/components/AudioWaves/audioWaves.module.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B","sourcesContent":["/* Other Audio Option stuff */\n#End1 {\n  visibility: hidden;\n}\n#End2 {\n  visibility: hidden;\n}\n#End3 {\n  visibility: hidden;\n}\n#End4 {\n  visibility: hidden;\n}\n\n#bars {\n  box-shadow: 0em 0em 1em black;\n}\n\n#Start1 {\n  visibility: visible;\n  fill: rgba(114, 85, 0, 1);\n}\n#Start2 {\n  visibility: visible;\n  fill: rgba(114, 85, 0, 1);\n}\n#Start3 {\n  visibility: visible;\n  fill: rgba(114, 85, 0, 1);\n}\n#Start4 {\n  visibility: visible;\n  fill: rgba(114, 85, 0, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"End1": "audioWaves_End1__19Uaq",
	"End2": "audioWaves_End2__o-Gpk",
	"End3": "audioWaves_End3__3fIWO",
	"End4": "audioWaves_End4__3mYWF",
	"bars": "audioWaves_bars__3xVJh",
	"Start1": "audioWaves_Start1__2xJwm",
	"Start2": "audioWaves_Start2__2jEMK",
	"Start3": "audioWaves_Start3__2kxro",
	"Start4": "audioWaves_Start4__3jwXi"
};
export default ___CSS_LOADER_EXPORT___;
