import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Components
import Overlay from "./pages/Overlay/Overlay";
import GraphicDesign from "./pages/GraphicDesign/GraphicDesign";
import Resume from "./pages/Resume/Resume";

// Contexts
import PanelContext from "./contexts/PanelContext";
import MediaQueryContext from "./contexts/MediaQueryContext";
import AudioContext from "./contexts/AudioContext";

function App() {
  const [page, setPage] = useState({});
  const panelContextValue = { page, setPage };

  //  for Media Query Context
  const [mediaQuery, setMediaQuery] = useState(window.innerWidth);
  const mediaQueryContextValue = { mediaQuery, setMediaQuery };

  // For audio Context
  const [playAudio, setPlayAudio] = useState({ fullVolume: false, halfVolume: false, muted: true });
  const audioContextValue = { playAudio, setPlayAudio };

  let vhMobile = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vhMobile", `${vhMobile}px`);

  let vwMobile = window.innerWidth * 0.01;
  document.documentElement.style.setProperty("--vwMobile", `${vwMobile}px`);


  // change the media query on resize of window
  window.addEventListener("resize", () => setMediaQuery(window.innerWidth));

  return (
    <PanelContext.Provider value={panelContextValue}>
      <MediaQueryContext.Provider value={mediaQueryContextValue}>
        <AudioContext.Provider value={audioContextValue}>
          <Router>
              <Route exact path="/" component={Overlay}></Route>
              <Route exact path="/resume">
                <Resume></Resume>
              </Route>
              <Route exact path="/graphics" component={GraphicDesign}></Route>
          </Router>
        </AudioContext.Provider>
      </MediaQueryContext.Provider>
    </PanelContext.Provider>
  );
}

export default App;
