import React, { useEffect, useState, useContext } from "react";
import gsap from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

// Contexts
import AudioContext from "../../contexts/AudioContext";
import MediaQueryContext from "../../contexts/MediaQueryContext";
import PanelContext from "../../contexts/PanelContext";

// Css
import CSS from "./audioWaves.module.css";
gsap.registerPlugin(DrawSVGPlugin, MorphSVGPlugin);
gsap.defaults({ overwrite: "auto" });

export default function Morph(props) {
  const {page, setPage} = useContext(PanelContext);
  const { playAudio, setPlayAudio } = useContext(AudioContext);
  const {mediaQuery} = useContext(MediaQueryContext);
  const [barOne, setBarOne] = useState();
  const [barTwo, setBarTwo] = useState();
  const [barThree, setBarThree] = useState();
  const [barFour, setBarFour] = useState();

  function yoyo() {
    setBarOne(
      gsap.to(`#${CSS.Start1}`, {
        duration: 0.7,
        morphSVG: { shape: `#${CSS.End1}`, type: "linear" },
        yoyo: true,
        repeat: -1,
      })
    );
    setBarTwo(
      gsap.to(`#${CSS.Start2}`, {
        duration: 0.73,
        morphSVG: { shape: `#${CSS.End2}`, type: "linear" },
        yoyo: true,
        repeat: -1,
      })
    );
    setBarThree(
      gsap.to(`#${CSS.Start3}`, {
        duration: 0.81,
        morphSVG: { shape: `#${CSS.End3}`, type: "linear" },
        yoyo: true,
        repeat: -1,
      })
    );
    setBarFour(
      gsap.to(`#${CSS.Start4}`, {
        duration: 0.64,
        morphSVG: { shape: `#${CSS.End4}`, type: "linear" },
        yoyo: true,
        repeat: -1,
      })
    );
  }

  function goDown() {
    setBarOne(
      gsap.to(`#${CSS.Start1}`, {
        duration: 0.7,
        morphSVG: { shape: `#${CSS.Start1}`, type: "linear" },
      })
    );
    setBarTwo(
      gsap.to(`#${CSS.Start2}`, {
        duration: 0.73,
        morphSVG: { shape: `#${CSS.Start2}`, type: "linear" },
      })
    );
    setBarThree(
      gsap.to(`#${CSS.Start3}`, {
        duration: 0.81,
        morphSVG: { shape: `#${CSS.Start3}`, type: "linear" },
      })
    );
    setBarFour(
      gsap.to(`#${CSS.Start4}`, {
        duration: 0.64,
        morphSVG: { shape: `#${CSS.Start4}`, type: "linear" },
      })
    );
  }

  function reset() {
    setBarOne(undefined);
    setBarTwo(undefined);
    setBarThree(undefined);
    setBarFour(undefined);
  }

  useEffect(() => {
    if (playAudio.muted) {
      // console.log("djskaldjaskldjalksal")
      return;
    } else {
      yoyo();
      // goUp()
    }
  }, []);

  useEffect(() => {
    if (
      playAudio.muted &&
      barOne !== undefined &&
      barTwo !== undefined &&
      barThree !== undefined &&
      barFour !== undefined
    ) {
      console.log("MUTED");
      barOne.kill();
      barTwo.kill();
      barThree.kill();
      barFour.kill();
      goDown();
      reset();
    } else if (
      barOne === undefined &&
      barTwo === undefined &&
      barThree === undefined &&
      barFour === undefined &&
      !playAudio.muted
    ) {
      yoyo();
    }
    console.log(playAudio);
  }, [playAudio]);

  useEffect(()=> {
    if(page.current === page.aboutRef) {
      gsap.to(`#${CSS.Start1}`, {duration: .5, fill: "rgba(114, 85, 0, 1)"})
      gsap.to(`#${CSS.Start2}`, {duration: .5, fill: "rgba(114, 85, 0, 1)"})
      gsap.to(`#${CSS.Start3}`, {duration: .5, fill: "rgba(114, 85, 0, 1)"})
      gsap.to(`#${CSS.Start4}`, {duration: .5, fill: "rgba(114, 85, 0, 1)"})
    } else {
      gsap.to(`#${CSS.Start1}`, {duration: .5, fill: "rgba(230, 208, 139, 1)"})
      gsap.to(`#${CSS.Start2}`, {duration: .5, fill: "rgba(230, 208, 139, 1)"})
      gsap.to(`#${CSS.Start3}`, {duration: .5, fill: "rgba(230, 208, 139, 1)"})
      gsap.to(`#${CSS.Start4}`, {duration: .5, fill: "rgba(230, 208, 139, 1)"})
    }
  }, [page.current])

  return (
    <div
      className={props.className}
      onClick={props.onClick}
      // onMouseEnter={props.onMouseEnter}
    >
      <svg
      id="bars"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width={mediaQuery < 500 ? "calc(64.24px / 15)" : "calc(64.24px / 10)"}
        height={mediaQuery < 500 ? "calc(448px / 15)" : "calc(448px / 10)"}
        viewBox="0 0 64.24 448"
        fill="currentColor"
      >
        <path
          id={`${CSS.Start1}`}
          d="M862.28,764H805.72a3.73,3.73,0,0,1-3.72-3.72V724.72a3.73,3.73,0,0,1,3.72-3.72h56.56a3.73,3.73,0,0,1,3.72,3.72v35.56A3.73,3.73,0,0,1,862.28,764Z"
          transform="translate(-801.76 -316.36)"
        />
        <path
          id={`${CSS.End1}`}
          d="M853.76,764.36h-40a12,12,0,0,1-12-12v-424a12,12,0,0,1,12-12h40a12,12,0,0,1,12,12v424A12,12,0,0,1,853.76,764.36Z"
          transform="translate(-801.76 -316.36)"
        />
      </svg>
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width={mediaQuery < 500 ? "calc(64.24px / 15)" : "calc(64.24px / 10)"}
        height={mediaQuery < 500 ? "calc(448px / 15)" : "calc(448px / 10)"}
        viewBox="0 0 64.24 448"
        fill="currentColor"
      >
        <path
          id={`${CSS.Start2}`}
          d="M862.28,764H805.72a3.73,3.73,0,0,1-3.72-3.72V724.72a3.73,3.73,0,0,1,3.72-3.72h56.56a3.73,3.73,0,0,1,3.72,3.72v35.56A3.73,3.73,0,0,1,862.28,764Z"
          transform="translate(-801.76 -316.36)"
        />
        <path
          id={`${CSS.End2}`}
          d="M853.76,764.36h-40a12,12,0,0,1-12-12v-424a12,12,0,0,1,12-12h40a12,12,0,0,1,12,12v424A12,12,0,0,1,853.76,764.36Z"
          transform="translate(-801.76 -316.36)"
        />
      </svg>
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width={mediaQuery < 500 ? "calc(64.24px / 15)" : "calc(64.24px / 10)"}
        height={mediaQuery < 500 ? "calc(448px / 15)" : "calc(448px / 10)"}
        viewBox="0 0 64.24 448"
        fill="currentColor"
      >
        <path
          id={`${CSS.Start3}`}
          d="M862.28,764H805.72a3.73,3.73,0,0,1-3.72-3.72V724.72a3.73,3.73,0,0,1,3.72-3.72h56.56a3.73,3.73,0,0,1,3.72,3.72v35.56A3.73,3.73,0,0,1,862.28,764Z"
          transform="translate(-801.76 -316.36)"
        />
        <path
          id={`${CSS.End3}`}
          d="M853.76,764.36h-40a12,12,0,0,1-12-12v-424a12,12,0,0,1,12-12h40a12,12,0,0,1,12,12v424A12,12,0,0,1,853.76,764.36Z"
          transform="translate(-801.76 -316.36)"
        />
      </svg>
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width={mediaQuery < 500 ? "calc(64.24px / 15)" : "calc(64.24px / 10)"}
        height={mediaQuery < 500 ? "calc(448px / 15)" : "calc(448px / 10)"}
        viewBox="0 0 64.24 448"
        fill="currentColor"
      >
        <path
          id={`${CSS.Start4}`}
          d="M862.28,764H805.72a3.73,3.73,0,0,1-3.72-3.72V724.72a3.73,3.73,0,0,1,3.72-3.72h56.56a3.73,3.73,0,0,1,3.72,3.72v35.56A3.73,3.73,0,0,1,862.28,764Z"
          transform="translate(-801.76 -316.36)"
        />
        <path
          id={`${CSS.End4}`}
          d="M853.76,764.36h-40a12,12,0,0,1-12-12v-424a12,12,0,0,1,12-12h40a12,12,0,0,1,12,12v424A12,12,0,0,1,853.76,764.36Z"
          transform="translate(-801.76 -316.36)"
        />
      </svg>
    </div>
  );
}
