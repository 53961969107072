import React, {forwardRef } from "react";

// This is the Contact Page

// Components
import Container from "../../components/Container/Container";

function Contact({...props}, ref) {
  return (
    <>
      <Container ref={ref} id={"Contact"} iden={"contact-max"}></Container>{" "}
    </>
  );
}

const forwardContact = forwardRef(Contact)

export default forwardContact