import React, { useState, useRef, useContext, useEffect, useLayoutEffect } from "react";
import { Route } from "react-router-dom";

import gsap from "gsap";

// Pages
import Intro from "../Intro/Intro";
import About from "../About/About";
import Projects from "../Projects/Projects";
import CreativeAbout from "../CreativeAbout/CreativeAbout";
import Resume from "../Resume/Resume";
import GraphicDesign from "../GraphicDesign/GraphicDesign";
import Contact from "../Contact/Contact";

// Components
import Nav from "../../components/Nav/Nav";
import Footer from "../../components/Footer/Footer";
import AudioSettings from "../../components/AudioSettings/AudioSettings";

// Context
import PanelContext from "../../contexts/PanelContext";
import MediaQueryContext from "../../contexts/MediaQueryContext";

// Util
import { switchPanels, regScrolling } from "./overlayUtil";

// CSS
import "../../App.css";
import CSS from "./overlay.module.css";

// This is where the scrolls and nav will go
export default function Overlay() {
  const [scrolling, setScrolling] = useState(undefined);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [count, setCount] = useState(0);

  const { page, setPage } = useContext(PanelContext);
  const { mediaQuery, setMediaQuery } = useContext(MediaQueryContext);

  const overlayRef = useRef();

  const introRef = useRef();
  const aboutRef = useRef();
  const projectsRef = useRef();
  const creativeRef = useRef();
  const contactRef = useRef();

  useEffect(() => {
    if (page === null) {
      console.log("\n\n\nI want to see this\n\n\n\n");
    }
    setPage({
      ...page,
      introRef,
      aboutRef,
      creativeRef,
      projectsRef,
      overlayRef,
      current: introRef,
      navClick: false,
    });
  }, []);

  useEffect(() => {
    console.log(scrollPosition);
  }, [scrollPosition]);

  useEffect(() => {
    console.log(count);
  }, [count]);

  return (
    <>
      <div
        ref={overlayRef}
        id={`Overlay`}
        className={`${CSS.scroll}`}
        onScroll={(e) => {
          // console.log(e.target.scrollTop);
          if (scrolling !== undefined) {
            return;
          } else if (mediaQuery < 500 && !page.navClick) {
            regScrolling({
              page,
              setPage,
              overlayY: e.target.scrollTop,
              scrollPosition,
              setScrollPosition,
            });
          } else if (mediaQuery > 1279 && !page.navClick) {
            // gsap.to(`#Overlay`, {overflowY: "hidden"});
            switchPanels({
              e,
              page,
              setPage,
              scrolling,
              setScrolling,
              scrollPosition,
              setScrollPosition,
            });
          }
        }}
      >
        <Nav setScrollPosition={setScrollPosition} iden={"overlaySideNav"}></Nav>
        <AudioSettings></AudioSettings>
        <Intro ref={introRef}></Intro>
        <About ref={aboutRef}></About>
        <Projects ref={projectsRef}></Projects>
        <CreativeAbout ref={creativeRef}></CreativeAbout>
        <Footer></Footer>

        {/* 
        <Route path="/">
          <Contact ref={contactRef}></Contact>
        </Route> */}
      </div>
    </>
  );
}
