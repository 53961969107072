import React, { useEffect, useState, useRef, useContext } from "react";
import gsap from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

import CSS from "./audioSettings.module.css";
import "../../App.css";

// import { playlist } from "../music";

import AudioContext from "../../contexts/AudioContext";
import PanelContext from "../../contexts/PanelContext";

import AudioWaves from "../AudioWaves/AudioWaves";

// Import FontAwesome Stuff
import { faVolumeUp, faVolumeDown, faVolumeMute } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

gsap.registerPlugin(DrawSVGPlugin, MorphSVGPlugin);
gsap.defaults({ overwrite: "auto" });

const playlist = ["/assets/audio/SleeplessNights.wav", "/assets/audio/KimHanbinRemixDemo-1.wav"];
export default function AudioOptions() {
  const {page, setPage} = useContext(PanelContext);
  const { playAudio, setPlayAudio } = useContext(AudioContext);
  const [showBarsAgain, setShowBarsAgain] = useState(undefined);

  // refs
  const fullVolume = useRef();
  const halfVolume = useRef();
  const noVolume = useRef();

  const music = useRef();

  function backToBars() {
    const tl = gsap.timeline();

    tl.to(`.${CSS.volIconContainer}`, {
      opacity: 0,
      display: "none",
      duration: 0.7,
    });
    tl.to(`.${CSS.barsContainer}`, {
      opacity: 1,
      display: "flex",
      duration: 0.7,
    });
  }

  useEffect(() => {
    if (playAudio.muted) {
      const tl = gsap.timeline();

      tl.to(`.${CSS.barsContainer}`, {
        opacity: 0,
        display: "none",
        duration: 0.7,
      });
      tl.to(`.${CSS.volIconContainer}`, {
        opacity: 1,
        display: "inherit",
        duration: 0.7,
      });
    }
  }, []);

  useEffect(() => {
    if(page.current === page.aboutRef) {
      gsap.to(`.${CSS.volumeIcons}`, {duration: .5, color: "rgba(114, 85, 0, 1)"})
    } else {
      gsap.to(`.${CSS.volumeIcons}`, {duration: .5, color: "rgba(230, 208, 139, 1)"})
    }
  }, [page.current])

  return (
    <>
      <div className={`${CSS.wholeContainer}`}>
        <AudioWaves
          className={`${CSS.barsContainer}`}
          onClick={() => {
            const tl = gsap.timeline();
            tl.to(`.${CSS.barsContainer}`, { opacity: 0, display: "none", duration: 0.7 });
            tl.to(`.${CSS.volIconContainer}`, {
              opacity: 1,
              display: "inherit",
              duration: 0.7,
              delay: 0.3,
            });
          }}
        ></AudioWaves>

        <div className={`${CSS.volIconContainer}`}>
          <div ref={fullVolume} className={playAudio.fullVolume ? "" : `${CSS.volNotChosen}`}>
            <FontAwesomeIcon
              className={`${CSS.volumeIcons}`}
              icon={faVolumeUp}
              onClick={(e) => {
                e.preventDefault();
                halfVolume.current.className = "";
                fullVolume.current.className = `${CSS.volNotChosen}`;
                music.current.volume = 0.2;
                setPlayAudio({ ...playAudio, fullVolume: false, halfVolume: true });
                if (showBarsAgain !== undefined) {
                  showBarsAgain.kill();
                }
                setShowBarsAgain(gsap.delayedCall(1.5, backToBars));
              }}
            ></FontAwesomeIcon>
          </div>

          <div ref={halfVolume} className={playAudio.halfVolume ? "" : `${CSS.volNotChosen}`}>
            <FontAwesomeIcon
              className={`${CSS.volumeIcons}`}
              icon={faVolumeDown}
              onClick={(e) => {
                e.preventDefault();
                noVolume.current.className = "";
                halfVolume.current.className = `${CSS.volNotChosen}`;
                music.current.volume = 0;
                music.current.pause();
                setPlayAudio({ ...playAudio, halfVolume: false, muted: true });
                if (showBarsAgain !== undefined) {
                  showBarsAgain.kill();
                }
                // setShowBarsAgain(gsap.delayedCall(1.5, backToBars));
              }}
            ></FontAwesomeIcon>
          </div>

          <div ref={noVolume} className={playAudio.muted ? "" : `${CSS.volNotChosen}`}>
            <FontAwesomeIcon
              className={`${CSS.volumeIcons}`}
              icon={faVolumeMute}
              onClick={(e) => {
                e.preventDefault();
                fullVolume.current.className = "";
                noVolume.current.className = `${CSS.volNotChosen}`;
                music.current.play();
                music.current.volume = 1;
                console.log(music);
                setPlayAudio({ ...playAudio, muted: false, fullVolume: true });
                if (showBarsAgain !== undefined) {
                  showBarsAgain.kill();
                }
                setShowBarsAgain(gsap.delayedCall(1.5, backToBars));
              }}
            ></FontAwesomeIcon>
          </div>
        </div>

        <audio
          ref={music}
          src={playlist[0]}
          type="audio/wav"
          onLoadedData={(e) => {
            if (playAudio.muted) {
              return;
            } else {
              console.log(e.target);
              e.target.play();
              // music.current.play();
            }
          }}
          onEnded={() => {
            console.log(music.current.currentTime);
            const localhost = "http://localhost:3000";
            if (music.current.src.substr(21) === playlist[0]) {
              music.current.src = localhost + playlist[1];
              music.current.play();
            }
          }}
        ></audio>
      </div>
    </>
  );
}
