import React, { forwardRef, useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import gsap from "gsap";

// This is the Landing Page

// Context

// CSS
import "../../App.css";
import CSS from "./container.module.css";
import INTRO from "./introPanel.module.css";
import ABOUT from "./aboutPanel.module.css";
import PROJECTS from "./projectsPanel.module.css";
import CREATIVE from "./creativePanel.module.css";

import PanelContext from "../../contexts/PanelContext";
import MediaQueryContext from "../../contexts/MediaQueryContext";

function Container({ iden, id, ...props }, ref) {
  const history = useHistory();
  const { page, setPage } = useContext(PanelContext);
  const { mediaQuery, setMediaQuery } = useContext(MediaQueryContext);
  const [hovering, setHovering] = useState(false);
  const [inspireHover, setInspireHover] = useState(false);
  const [infoClick, setInfoClick] = useState("none");
  useEffect(() => {
    // console.log(ref);
  }, []);

  return (
    <>
      {!iden || iden === undefined ? (
        <></>
      ) : /*
      ======================== Intro ========================
      */
      iden === "intro-max-mobile" ? (
        <div id={id} ref={ref} className={`${INTRO.bg}`}>
          <div className={`${INTRO.main}`}>
            <img
              className={`${INTRO.photo}`}
              alt={`Daniel Thao`}
              src={`./assets/img/PortPic1.jpg`}
              // src={`https://images.unsplash.com/photo-1524492926121-4723520d78d9?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80`}
            />
            <h1 className={`${INTRO.statement}`}>CREATOR AT HEART</h1>
          </div>
        </div>
      ) : /*
      ======================== About ========================
      */
      iden === "about-max-mobile" ? (
        <div id={id} ref={ref} className={`${ABOUT.bg}`}>
          <div className={`${ABOUT.info}`}>
            <h1 className={`${ABOUT.name}`}>DANIEL THAO</h1>
            <p className={`${ABOUT.paragraphs}`}>
              Full-stack web developer adept in Javascript, CSS, HTML, and these
              technologies: React/React-Native, Node, Express, Mongo, MySQL,
              Auth0 and many more.
            </p>
            <p className={`${ABOUT.paragraphs}`}>
              I'm a creator at heart and that means taking ownership of the
              things I'm a part of and pouring passionate energy in seeing the
              project through. I excel in low-level visualization, high-level
              planning and the execution of both.
            </p>
            <div
              className={`${ABOUT.resume_block}`}
              onClick={() => {
                history.push("/resume");
              }}
            >
              <h1 className={`${ABOUT.resume}`}>RESUME</h1>
              {/* <a href="/public/assets/img/Thao_Resume02.pdf" height="800" width="1140" target="_blank">example</a> */}
              {/* <iframe src="assets/img/Thao_Resume02.pdf" height="800" width="1140"></iframe> */}
            </div>
          </div>
        </div>
      ) : /*
      ======================== Projects ========================
      */
      iden === "projects-max-mobile" ? (
        <div id={id} ref={ref} className={`${PROJECTS.fmty_bg}`}>
          <div className={`${CREATIVE.title_container}`}>
            <h1 className={`${PROJECTS.project_title}`}>PROJECTS</h1>
            <h1 className={`${PROJECTS.project_titleShadow}`}>PROJECTS</h1>
          </div>
          <div className={`${PROJECTS.highlight_reel}`}>
            <div
              className={`${PROJECTS.highlight}`}
              onClick={() => {
                if (mediaQuery < 500 && infoClick !== "fmty") {
                  setInfoClick("fmty");
                } else {
                  setInfoClick("none");
                }
              }}
              onMouseEnter={() => {
                if (mediaQuery > 1279) {
                  gsap.to(`.${PROJECTS.FromMeToYou_img}`, {
                    duration: 0.25,
                    ease: "none",
                    transform: "scale(1.1, 1.1)",
                  });
                  setHovering("fmty");
                }
              }}
              onMouseLeave={() => {
                if (mediaQuery > 1279) {
                  gsap.to(`.${PROJECTS.FromMeToYou_img}`, {
                    duration: 0.25,
                    ease: "none",
                    transform: "scale(1, 1)",
                  });
                  setHovering("none");
                }
              }}
            >
              <img
                className={PROJECTS.FromMeToYou_img}
                src={
                  "https://images.unsplash.com/photo-1569144157581-984dea473e3b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
                }
              ></img>
              {(hovering === "fmty" && mediaQuery > 1279) ||
              infoClick === "fmty" ? (
                <div className={`${PROJECTS.info_box}`}>
                  <h1 className={`${PROJECTS.title}`}>From Me to You</h1>
                  <p className={`${PROJECTS.text}`}>
                    A social chat app with that has many of basic features used
                    on Facebook: friend acceptance, declination, unfriending,
                    searching, chats between friends, personal profiles with
                    personal timelines, and a friend-linked social feed.
                  </p>

                  <p className={`${PROJECTS.text}`}>
                    Tech: React, Express, MySQL, Sequelize, JSON webtokens,
                    Bcrypt, Concurrently, Axios.
                  </p>

                  <p className={`${PROJECTS.text}`}>
                    DEMO Login Credintials: - Highlightable
                    <hr></hr>
                  </p>
                  <p className={`${PROJECTS.text2}`}>
                    User1 Email: userone@userone.com
                    <br></br>
                    User1 Password: u@3r0Ne
                  </p>
                  <p className={`${PROJECTS.text3}`}>
                    User2 Email: usertwo@usertwo.com
                    <br></br>
                    User2 Password: u@3rTW0
                  </p>
                  <p className={`${PROJECTS.text3}`}>
                    User3 Email: userthree@userthree.com
                    <br></br>
                    User3 Password: u@3rThr3E
                  </p>
                  <p className={`${PROJECTS.text3}`}>
                    User4 Email: userfour@userfour.com
                    <br></br>
                    User4 Password: u@3rF0ur
                  </p>
                  <div className={`${PROJECTS.codeLinks_con}`}>
                    <a
                      href="https://github.com/daniel-thao/From-Me-To-You"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`${PROJECTS.codeLinks}`}
                    >
                      Github
                    </a>
                    <a
                      href="https://from-me-to-you-app.herokuapp.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`${PROJECTS.codeLinks}`}
                    >
                      Deployed App
                    </a>
                  </div>
                </div>
              ) : (
                <div className={`${PROJECTS.fmty_grid}`}>
                  <h1 className={`${PROJECTS.fmty_title_one}`}>From Me</h1>
                  <h1 className={`${PROJECTS.fmty_title_two}`}>To You</h1>
                </div>
              )}
            </div>

            <div
              className={`${PROJECTS.highlight}`}
              onClick={() => {
                if (mediaQuery < 500 && infoClick !== "fof") {
                  setInfoClick("fof");
                } else {
                  setInfoClick("none");
                }
              }}
              onMouseEnter={() => {
                if (mediaQuery > 1279) {
                  gsap.to(`.${PROJECTS.FistOfFive_bg}`, {
                    duration: 0.25,
                    ease: "none",
                    transform: "scale(1.1, 1.1)",
                  });
                  setHovering("fof");
                }
              }}
              onMouseLeave={() => {
                if (mediaQuery > 1279) {
                  gsap.to(`.${PROJECTS.FistOfFive_bg}`, {
                    duration: 0.25,
                    ease: "none",
                    transform: "scale(1, 1)",
                  });
                  setHovering("none");
                }
              }}
            >
              <img
                className={PROJECTS.FistOfFive_bg}
                src={
                  "https://images.unsplash.com/photo-1535478044878-3ed83d5456ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEwMjc5NH0&auto=format&fit=crop&w=1369&q=80"
                }
              ></img>
              {(hovering === "fof" && mediaQuery > 1279) ||
              infoClick === "fof" ? (
                <div className={`${PROJECTS.info_box}`}>
                  <h1 className={`${PROJECTS.title}`}>Fist of Five</h1>
                  <p className={`${PROJECTS.text}`}>
                    An app for administrators to keep track of the level
                    comprehension of users through user input. Admins are able
                    to preview and manipulate their data: deletion of users and
                    user input. Non-admins interact with the app through their
                    input.
                  </p>

                  <p className={`${PROJECTS.text}`}>
                    Tech: React, Express, Mongo, Mongoose, JSON webtokens,
                    Bcrypt, Concurrently, Axios, DayJS, Passport.
                  </p>

                  <p className={`${PROJECTS.text}`}>
                    DEMO Login Credintials: - Highlightable
                    <hr></hr>
                  </p>

                  <p className={`${PROJECTS.text2}`}>
                    Admin Email: admin@admin.com
                    <br></br>
                    Admin Password: @dm!nU
                  </p>

                  <p className={`${PROJECTS.text3}`}>
                    User Email: user@user.com
                    <br></br>
                    User Password: U$3r01
                  </p>

                  <div className={`${PROJECTS.codeLinks_con}`}>
                    <a
                      href="https://github.com/daniel-thao/Fist-Of-Five"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`${PROJECTS.codeLinks}`}
                    >
                      Github
                    </a>
                    <a
                      href="https://fist-to-five-tabz.herokuapp.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`${PROJECTS.codeLinks}`}
                    >
                      Deployed App
                    </a>
                  </div>
                </div>
              ) : (
                <div>
                  <div className={`${PROJECTS.FistOfFive_overlay}`}>
                    <img
                      className={`${PROJECTS.FistOfFive_img}`}
                      src={`/assets/img/FistToFive_1.png`}
                    ></img>
                  </div>
                </div>
              )}
            </div>

            <div
              className={`${PROJECTS.highlight}`}
              onClick={() => {
                if (mediaQuery < 500 && infoClick !== "inspire") {
                  setInfoClick("inspire");
                } else {
                  setInfoClick("none");
                }
              }}
              onMouseEnter={() => {
                if (mediaQuery > 1279) {
                  setHovering("inspire");
                  gsap.fromTo(
                    `.${PROJECTS.inspire_statement}`,
                    {
                      paddingTop: "calc(100vw / 30)",
                      paddingBottom: "calc(100vw / 30)",
                    },
                    {
                      duration: 0.25,
                      ease: "none",
                      paddingTop: "calc(100vw / 20)",
                      paddingBottom: "calc(100vw / 20)",
                    }
                  );
                }
              }}
              onMouseLeave={() => {
                if (mediaQuery > 1279) {
                  setHovering("none");
                  gsap.fromTo(
                    `.${PROJECTS.inspire_statement}`,
                    {
                      paddingTop: "calc(100vw / 20)",
                      paddingBottom: "calc(100vw / 20)",
                    },
                    {
                      duration: 0.25,
                      ease: "none",
                      paddingTop: "calc(100vw / 30)",
                      paddingBottom: "calc(100vw / 30)",
                    }
                  );
                }
              }}
            >
              {infoClick === "inspire" ||
              (hovering == "inspire" && mediaQuery > 1279) ? (
                <div className={`${PROJECTS.inspire_wholeCon}`}>
                  <div
                    className={`${PROJECTS.inpsire_main} ${PROJECTS.inpsire_main_mobile}`}
                  >
                    <h1 className={`${PROJECTS.inspire_statement}`}>
                      INSPIRE INSPIRATION
                    </h1>
                  </div>

                  <div className={`${PROJECTS.info_box}`}>
                    <h1 className={`${PROJECTS.title}`}>Inspire Inspiration</h1>
                    <p className={`${PROJECTS.text}`}>
                      A motivational goal management app. When entering, users
                      are given a motivational video to either watch or listen
                      to. They also are able to set daily and future goals
                    </p>

                    <p className={`${PROJECTS.text}`}>
                      Tech: React, Express, Mongo, Mongoose, AuthO, GSAP
                      Animations, Concurrently, Axios, DayJS, FontAwesome.
                    </p>

                    <p className={`${PROJECTS.text}`}>
                      DEMO Login Credintials:
                      <hr></hr>
                    </p>

                    <p className={`${PROJECTS.text2}`}>Google Auth</p>
                    <div className={`${PROJECTS.codeLinks_con}`}>
                      <a
                        href="https://github.com/daniel-thao/Inspira-Instinctu"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`${PROJECTS.codeLinks}`}
                      >
                        Github
                      </a>
                      <a
                        href="https://inspire-inspiration.herokuapp.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`${PROJECTS.codeLinks}`}
                      >
                        Deployed App
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={`${PROJECTS.inspire_wholeCon}`}>
                  <div className={`${PROJECTS.inpsire_main}`}>
                    <h1 className={`${PROJECTS.inspire_statement}`}>
                      INSPIRE INSPIRATION
                    </h1>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : /*
      ======================== Creative About ========================
      */
      iden === "creative-about-max-mobile" ? (
        <div id={id} ref={ref} className={`${CREATIVE.bg}`}>
          <div className={`${CREATIVE.title_container}`}>
            <h1 className={`${CREATIVE.title}`}>ARTISTRY</h1>
            <h1 className={`${CREATIVE.titleShadow}`}>ARTISTRY</h1>
          </div>

          <p className={`${CREATIVE.statement}`}>
            My creativeness extends to many art studies. I practice these most
            often.
          </p>

          <div className={`${CREATIVE.reel}`}>
            <div className={`${CREATIVE.study} ${CREATIVE.music}`}>
              <a
                href="https://soundcloud.com/taboothao"
                target="_blank"
                rel="noopener noreferrer"
                className={`${CREATIVE.hyperlink}`}
              >
                <img
                  className={CREATIVE.music_img}
                  src={
                    "https://images.unsplash.com/photo-1478737270239-2f02b77fc618?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80"
                  }
                ></img>
                <h1 className={`${CREATIVE.music_text}`}>MUSIC</h1>
              </a>
            </div>

            <div className={`${CREATIVE.study} ${CREATIVE.music}`}>
              <a
                href="https://www.youtube.com/channel/UCtyEQd3LSfvmFE4x1gjTGbg/featured"
                target="_blank"
                rel="noopener noreferrer"
                className={`${CREATIVE.hyperlink}`}
              >
                <img
                  className={CREATIVE.dance_img}
                  src={
                    "https://images.unsplash.com/photo-1544274367-aafa64a78bac?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                  }
                ></img>
                <h1 className={`${CREATIVE.dance_text}`}>DANCE</h1>
              </a>
            </div>

            <div
              className={`${CREATIVE.study} ${CREATIVE.graphic}`}
              onClick={() => {
                history.push("/graphics");
              }}
              onMouseEnter={() => {
                if (mediaQuery > 1279) {
                  gsap.to(`.${CREATIVE.graphic_img}`, {
                    duration: 0.25,
                    ease: "none",
                    transform: "scale(.9, .9)",
                  });
                }
              }}
              onMouseLeave={() => {
                if (mediaQuery > 1279) {
                  gsap.to(`.${CREATIVE.graphic_img}`, {
                    duration: 0.25,
                    ease: "none",
                    transform: "scale(.8, .8)",
                  });
                }
              }}
            >
              {mediaQuery < 1279 ? (
                <img
                  className={CREATIVE.graphic_img}
                  src={"assets/img/HappyPot_1.png"}
                ></img>
              ) : (
                <img
                  className={CREATIVE.graphic_img}
                  src={"assets/img/UsedToBeAsAmLogo.png"}
                ></img>
              )}
              <div>
                <h1
                  className={`${CREATIVE.graphic_text} ${CREATIVE.graphic_graphic}`}
                >
                  GRAPHIC
                </h1>
                <h1
                  className={`${CREATIVE.graphic_text} ${CREATIVE.graphic_design}`}
                >
                  DESIGN
                </h1>
              </div>
            </div>
          </div>
        </div>
      ) : /*
      ======================== Contact ========================
      */
      iden === "contact-max-mobile" ? (
        <div id={id} ref={ref} className={`${CSS.contact_bg}`}>
          Contact
        </div>
      ) : (
        ""
      )}
    </>
  );
}

const forwardContainer = forwardRef(Container);

export default forwardContainer;
