import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

// FontAwesome
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CSS from "./graphic.module.css";
import PanelContext from "../../contexts/PanelContext";

const graphicStuff = [
  "assets/img/Deepseeded_1.png",
  "assets/img/FistToFive.png",
  "assets/img/Flower_3_4.png",
  "assets/img/HappyPot_1.png",
  "assets/img/HoldingBag_1.png",
  "assets/img/KBBQ_2.png",
  "assets/img/Logo_5.21(small).png",
  "assets/img/UsedToBeAsAmLogo.png",
  "assets/img/WhatAreWords.jpg",
];

export default function GraphicDesign() {
  const history = useHistory();
  const { page, setPage } = useContext(PanelContext);

  useEffect(() => {
    setPage({ ...page, offOverlay: true });
  }, []);

  function exit(event) {
    setPage({ ...page, offOverlay: false });
    history.push("/");
  }

  return (
    <div className={`${CSS.graphics_bg}`}>
      <h1 className={`${CSS.graphics_title}`}>GRAPHICS</h1>
      <div className={`${CSS.graphics_allIcons}`}>
        <div className={`${CSS.graphics_iconCon}`}>
          <img
            className={`${CSS.graphics_icons} ${CSS.graphics_lotus}`}
            alt={`Daniel Thao`}
            src="assets/img/Flower_3_4.png"
          />
        </div>

        <div className={`${CSS.graphics_iconCon}`}>
          <img
            className={`${CSS.graphics_icons} ${CSS.graphics_chakras}`}
            alt={`Daniel Thao`}
            src="assets/img/UsedToBeAsAmLogo.png"
          />
        </div>

        <div className={`${CSS.graphics_iconCon}`}>
          <img
            className={`${CSS.graphics_icons} ${CSS.graphics_happyPot}`}
            alt={`Daniel Thao`}
            src="assets/img/HappyPot_1.png"
          />
        </div>

        <div className={`${CSS.graphics_iconCon}`}>
          <img
            className={`${CSS.graphics_icons} ${CSS.graphics_kbbq}`}
            alt={`Daniel Thao`}
            src="assets/img/KBBQ_2.png"
          />
        </div>

        <div className={`${CSS.graphics_iconCon}`}>
          <img
            className={`${CSS.graphics_icons} ${CSS.graphics_DS}`}
            alt={`Daniel Thao`}
            src="assets/img/Deepseeded_1.png"
          />
        </div>

        <div className={`${CSS.graphics_iconCon}`}>
          <img
            className={`${CSS.graphics_icons} ${CSS.graphics_TT}`}
            alt={`Daniel Thao`}
            src="assets/img/Logo_5.21(small).png"
          />
        </div>
      </div>

      <div className={CSS.exit} onClick={exit}>
        <FontAwesomeIcon icon={faWindowClose}></FontAwesomeIcon>
      </div>
    </div>
  );
}
