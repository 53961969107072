import React, {useContext} from "react";
import { useHistory } from "react-router-dom";

// FontAwesome
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PanelContext from "../../contexts/PanelContext";


import CSS from "./resume.module.css";

export default function Resume() {
  const history = useHistory();
  const {page, setPage} = useContext(PanelContext);
  function exit(event) {
    console.log("\n\nexiting Resume\n\n")
    history.push("/");
  }

  return (
    <div className={`${CSS.darkBorder}`}>
      {/* <div> */}
      <iframe
        //   title="displayingPDF"
        className={CSS.fit}
        //   src={pdf}
        src="assets/img/Thao, Daniel_Resume.pdf"
        type="applicaiton/pdf"
        height="100vh"
        width="100vw"
      />
      {/* </div> */}
      <div className={CSS.exit} onClick={exit}>
        <FontAwesomeIcon icon={faWindowClose}></FontAwesomeIcon>
      </div>
    </div>
  );
}
