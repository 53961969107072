import React, { forwardRef } from "react";

// This is the Landing Page
// Components
import Container from "../../components/Container/Container";

// CSS
import "../../App.css";
function Intro({ ...props }, ref) {
  return (
    <>
      <Container id={"Intro"} ref={ref} iden={"intro-max-mobile"}></Container>
    </>
  );
}

const forwardIntro = forwardRef(Intro);

export default forwardIntro;
