import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import CSS from "./overlay.module.css";

gsap.registerPlugin(MotionPathPlugin, ScrollToPlugin);

// function enable(e) {
//   gsap.to(`#${e.target.id}`, {overflowY: "scroll"})
// }

export const switchPanels = function ({
  e,
  page,
  setPage,
  scrolling,
  setScrolling,
  scrollPosition,
  setScrollPosition,
  disableBodyScroll,
  enableBodyScroll,
  overlayRef,
}) {
  // console.log(page);
  switch (scrollPosition) {
    case page.introRef.current.offsetTop:
      if (e.target.scrollTop > page.introRef.current.offsetTop && scrolling === undefined) {
        setScrolling(
          gsap.to(`.${CSS.scroll}`, {
            duration: 1,
            scrollTo: { y: `#${page.aboutRef.current.id}` },
            ease: "power4",
          })
        );
        setScrollPosition(page.aboutRef.current.offsetTop);
        gsap.delayedCall(1, setScrolling, [undefined]);
        setPage({ ...page, current: page.aboutRef });
      }
      break;
    case page.aboutRef.current.offsetTop:
      if (e.target.scrollTop > page.aboutRef.current.offsetTop && scrolling === undefined) {
        setScrolling(
          gsap.to(`.${CSS.scroll}`, {
            duration: 1,
            scrollTo: `#${page.projectsRef.current.id}`,
            ease: "power4",
          })
        );
        setScrollPosition(page.projectsRef.current.offsetTop);
        gsap.delayedCall(1, setScrolling, [undefined]);
        setPage({ ...page, current: page.projectsRef });
      } else if (e.target.scrollTop < page.aboutRef.current.offsetTop && scrolling === undefined) {
        setScrolling(
          gsap.to(`.${CSS.scroll}`, {
            duration: 1,
            scrollTo: `#${page.introRef.current.id}`,
            ease: "power4",
          })
        );
        setScrollPosition(page.introRef.current.offsetTop);
        gsap.delayedCall(1, setScrolling, [undefined]);
        setPage({ ...page, current: page.introRef });
      }
      break;
    case page.projectsRef.current.offsetTop:
      if (e.target.scrollTop > page.projectsRef.current.offsetTop && scrolling === undefined) {
        setScrolling(
          gsap.to(`.${CSS.scroll}`, {
            duration: 1,
            scrollTo: `#${page.creativeRef.current.id}`,
            ease: "power4",
          })
        );
        setScrollPosition(page.creativeRef.current.offsetTop);
        gsap.delayedCall(1, setScrolling, [undefined]);
        setPage({ ...page, current: page.creativeRef });
      } else if (
        e.target.scrollTop < page.projectsRef.current.offsetTop &&
        scrolling === undefined
      ) {
        setScrolling(
          gsap.to(`.${CSS.scroll}`, {
            duration: 1,
            scrollTo: `#${page.aboutRef.current.id}`,
            ease: "power4",
          })
        );
        setScrollPosition(page.aboutRef.current.offsetTop);
        gsap.delayedCall(1, setScrolling, [undefined]);
        setPage({ ...page, current: page.aboutRef });
      }
      break;
    case page.creativeRef.current.offsetTop:
      if (e.target.scrollTop < page.creativeRef.current.offsetTop && scrolling === undefined) {
        setScrolling(
          gsap.to(`.${CSS.scroll}`, {
            duration: 1,
            scrollTo: `#${page.projectsRef.current.id}`,
            ease: "power4",
          })
        );
        setScrollPosition(page.projectsRef.current.offsetTop);
        gsap.delayedCall(1, setScrolling, [undefined]);
        setPage({ ...page, current: page.projectsRef });
      }
      break;
  }
};

export const regScrolling = function ({
  page,
  setPage,
  overlayY,
  scrollPosition,
  setScrollPosition,
}) {
  // console.log(page.current);
  switch (page.current) {
    case page.introRef:
      if (overlayY > page.aboutRef.current.offsetTop / 1.6) {
        return setPage({ ...page, current: page.aboutRef });
      }
    case page.aboutRef:
      if (overlayY < page.aboutRef.current.offsetTop / 1.6) {
        return setPage({ ...page, current: page.introRef });
      } else if (overlayY > page.projectsRef.current.offsetTop / 1.275) {
        return setPage({ ...page, current: page.projectsRef });
      }
    case page.projectsRef:
      if (overlayY < page.projectsRef.current.offsetTop / 1.275) {
        return setPage({ ...page, current: page.aboutRef });
      } else if (overlayY > page.creativeRef.current.offsetTop / 1.2) {
        return setPage({ ...page, current: page.creativeRef });
      }
    case page.creativeRef:
      if (overlayY < page.creativeRef.current.offsetTop / 1.2) {
        return setPage({ ...page, current: page.projectsRef });
      }
    default:
      return;
  }
};
